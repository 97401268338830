import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'ShibSwap Finance - The best AMM DEX on ShibSwap Finance (CORE)',
  description:
    'The most popular AMM on ShibSwap Finance by user count! Earn ShibSwap through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by ShibSwap Finance), NFTs, and more, on a platform you can trust.',
  image: 'https://swap.shibcore.xyz/images/swapimg/twitter.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Home')} | ${t('ShibSwap Finance')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('ShibSwap Finance')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('ShibSwap Finance')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('ShibSwap Finance')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('ShibSwap Finance')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('ShibSwap Finance')}`,
      }
    case '/collectibles':
      return {
        title: `${t('Collectibles')} | ${t('ShibSwap Finance')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('ShibSwap Finance')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('ShibSwap Finance')}`,
      }
    case '/profile/tasks':
      return {
        title: `${t('Task Center')} | ${t('ShibSwap Finance')}`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | ${t('ShibSwap Finance')}`,
      }
    default:
      return null
  }
}
