import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
 
  
	{
		label: t('Core'),
		icon: 'Link2Icon',
		 
		
		
		items: [
		   
			
		
			
				
		],},
  
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Swap'),
        href: '/swap?outputCurrency=0x3Eb3770bEd92cDb09F3eF44058B217C6Fd0E8160',
      },
      {
        label: t('Liquidity'),
        href: '/pool',

      },
    ],
  },
  
 
  
	{
	label: t('Staking'),
	icon: 'PoolIcon',
	href: 'https://pipitool.com/staking/pools',
	},
				
    
		
		
		
		
		
		
		
		
		
		
  
  
 
        



]

export default config
